<template>
    <div style="">
        <v-row class="px-3">
            <v-col cols="12" md="9">
                <v-card min-height="80vh">
                    <v-list>
                        <v-subheader style="text-decoration: underline;" class="black--text title">Tin tức</v-subheader>
                        <v-list-item v-for="content in contents" :key="content.code" class="d-block d-md-flex">
                            <v-list-item-avatar tile size="200" color="grey" >
                                <v-img
                                    :alt="`${content.title} avatar`"
                                    :src="content.avatar"
                                ></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                            <div class="overline mb-4">{{content.category}}</div>
                            <v-list-item-title class="headline mb-1 pink--text">{{content.title}}</v-list-item-title>
                            <v-list-item-subtitle class="mb-1 font-weight-medium">{{ diffToHuman(content.createdAt) }}</v-list-item-subtitle>
                            <div>{{content.brief}}</div>
                            
                            <router-link  class="orange--text" :to="{ name: 'NewsDetail', params: { id: content.code }}">
                                <span>Chi tiết</span>
                            </router-link>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card min-height="">
                    <v-list class="py-0 pb-4" color="lime lighten-5">
                        <v-subheader style="text-decoration: underline;" class="title black--text">Danh mục</v-subheader>
                        <v-list-item v-for="category in categories" :key="category.code" color="teal" :to="{name:'NewsCategory', params: {category:category.code}}">
                            <v-list-item-title class="subtitle-2">
                                {{category.title}}
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
const NEWS_URL = "contents/shows";
import http from '@/components/services/http-common';
import moment from 'moment';
export default {
    name: "NewsIndex",
    props: ["breadcrumbs"],
    components:{
    },
    data(){
        return {
            contents: [],
            categories:[],
        }
    },
    methods:{
        diffToHuman(value){
            let date = moment(value, "YYYY-MM-DD").locale("vi");
            return moment(date).fromNow();
        },
        fetchData() {
            return http.get(NEWS_URL,{
                            params: {
                                ContentCategoryCode: this.$route.params.category,
                            }
                        }).then(res => {
                this.contents = res.data;
            }).catch(e => {
                console.log("xay ra loi khi load du lieu", e);
            })
        },
        fetchCategory(){
            return http.get("ContentCategories").then(res => {
                this.categories = res.data.results;
            });
        },
    },
    created(){
        this.fetchData();
        this.fetchCategory();
    },
    
    destroyed() {
    }
}
</script>

